<!--
File: Deu.vue
Description: show list of DEU's entered in the DB.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown :label="$t('label.select_region')" v-model="selected_region" data-vv-name="selected_region"
            :initial_value="selected_region" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px" :placeholder="$t('label.search_records')"
              v-model="searchQuery"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="addNewDeu"> {{ $t('buttons.add') }} </md-button>
        </div>
      </div>
    </md-card-header>
    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
        md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')">
        </md-table-empty-state>
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell v-if="!selected_region" :md-label="$t('road_network.region')" md-sort-by="region">
            {{ item.region }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.dep')" md-sort-by="description">
            {{ item.description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('stdCols.address')" md-sort-by="address1">
            {{ item.address1 }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.city')" md-sort-by="city">
            {{ item.city }}
          </md-table-cell>
          <md-table-cell :md-label="$t('tables.actions')">
            <div>
              <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                @click.native="editDeu(item.deu_id)">
                <md-icon>edit</md-icon>
              </md-button>
              <md-button v-if="amIAdmin" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                @click.stop.prevent="handleDelete(item)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="footer-table md-table">
        <table>
          <tfoot>
            <tr>
              <th v-for="item in footerTable" :key="item.name" class="md-table-head">
                <div class="md-table-head-container md-ripple md-disabled">
                  <div class="md-table-head-label">{{ item }}</div>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </md-card-content>
    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
  </md-card>
</template>
<script>
  import { Pagination } from '@/components'
  import Fuse from 'fuse.js'
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import { customSortMixin } from '@/mixins/customSortMixin'
  import messages from '@/mixins/messagesMixin'

  export default {
    mixins: [customSortMixin, messages],
    name: 'deus-list',
    data() {
      return {
        selected_region: null,
        showSpinner: false,

        pagination: {
          perPage: 20,
          currentPage: 1,
          perPageOptions: [10, 20, 30, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['region', 'description', 'city', 'address1'],
        searchedData: [],
        currentSort: 'region',
        currentSortOrder: 'asc',
        fuseSearch: null,
        footerTable: []
      }
    },
    components: {
      Pagination,
      RegionsDropdown,
    },

    mounted() {
      this.showSpinner = true
      this.$store.dispatch('LOAD_HISTORY').then((history) => {
        if (history.for === 'deus' && history.use) {
          this.selected_region = history.data.selected_region
          this.currentSort = history.data.currentSort
          this.currentSortOrder = history.data.currentSortOrder
          this.pagination.perPage = history.data.perPage
          this.$nextTick(() => {
            this.searchQuery = history.data.searchQuery
            this.pagination.currentPage = history.data.currentPage
          })
          this.$store.dispatch('CLEAR_HISTORY')
        } else {
          this.selected_region = null
        }
        this.reloadData(this.searchQuery)
        this.showSpinner = false
      })
    },

    methods: {
      reloadData(searchQuery) {
        this.showSpinner = true
        this.searchQuery = ''
        this.$store.dispatch('LOAD_DEU_LIST').then(() => {
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.deuList, { keys: this.propsToSearch, threshold: 0.3 })
          if (searchQuery) this.searchQuery = searchQuery
          this.showSpinner = false
        })
      },

      save_history() {
        const hist = {
          selected_region: this.selected_region,
          searchQuery: this.searchQuery,
          currentSort: this.currentSort,
          currentSortOrder: this.currentSortOrder,
          perPage: this.pagination.perPage,
          currentPage: this.pagination.currentPage
        }
        localStorage.setItem('history', btoa(unescape(encodeURIComponent(JSON.stringify(hist)))))
      },

      clear_history() {
        localStorage.setItem('history_for', '')
      },

      editDeu(id) {
        this.save_history()
        this.$router.push(`/inventory_data/deu/upd/${id}`)
      },

      addNewDeu() {
        this.save_history()
        this.$router.push(`/inventory_data/deu/add`)
      },

      handleDelete(item) {
        this.save_history()
        this.deleteConfirmation(item.description).then((result) => {
          if (result.value) {
            this.showSpinner = true
            this.$store.dispatch('DEL_DEU', item.deu_id).then(
              () => {
                this.reloadData(this.searchQuery)
                this.deletedMessage(item.description)
              },
              (err) => {
                this.errorMessage(err)
              }
            )
            this.showSpinner = false
          }
        })
      },
    },

    computed: {
      history_for() {
        //return this.$store.state.Roads.use_history
        return localStorage.getItem('history_for')
      },
      history() {
        //return this.$store.state.Roads.history
        return JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('history')))))
      },
      amIAdmin() {
        return this.$store.getters.amIAdmin
      },
      deuList() {
        const res = this.$store.state.Deu.list.map(item => ({
          ...item,
          description: `${this.$t('road_network.dep')}-${item.description}`
        }))
        return this.customSort(res, 'description')
      },
      queriedData() {
        const result = !this.searchQuery ? this.tableData : this.searchedData ? this.searchedData : []
        return result.slice(this.from, this.to)
      },
      to() {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total() {
        return this.searchQuery ? this.searchedData.length : this.tableData.length
      },
      tableData() {
        return this.selected_region ? this.deuList.filter((item) => {
          return item.fk_region === this.selected_region
        }) : this.deuList
      }
    },

    watch: {
      searchQuery(value) {
        if (this.deuList.length == 0) {
          this.searchedData = []
          return
        }
        this.searchedData = (value === '' || !this.fuseSearch) ? this.tableData : this.fuseSearch.search(this.searchQuery)
      }
    }
  }
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.paginated-table table>tbody>tr>td {
  padding: 0px !important;
  outline: 0;
  width: 150px;
  border-right: 0;
  border-bottom: 0;
}

.md-button+.md-button {
  margin-left: 10px;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>